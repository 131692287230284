import DOMPurify from 'dompurify'

const containsHTML = (input) => {
    const sanitized = DOMPurify.sanitize(input)
    return sanitized !== input // Returns true if input contained HTML
}

export default containsHTML

export enum GRID_UTILS {
    PAGE = 'page',
    SIZE = 'per_page',
    PAGE_SIZE = '20'
}
