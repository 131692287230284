import { getPostHeaders } from 'common/util/requestUtils'

export const getPreGenerationStatus = async (committee_id: any) => {
    try {
        return fetch(`/api/minutes_documents/pregeneration_status?committees=[${committee_id}]`, {
            method: 'GET',
            headers: getPostHeaders()
        }).then((response) => {
            const data = response.json()
            return data
        })
    } catch (error) {
        throw new Error('Issue with fetching pregeneration status')
    }
}
