import i18n from 'common/util/internationalization'
import { getPostHeaders } from 'common/util/requestUtils'

export const getNotificationSettings = async () => {
    try {
        return fetch(`/api/minutes_settings`, {
            method: 'GET',
            headers: getPostHeaders()
        }).then((response) => {
            //TODO: handle erros

            const data = response.json()

            return data
        })
    } catch (error) {
        throw new Error(i18n.t('AI_REWRITE_ERROR'))
    }
}

export const postNotificationSettings = async (body) => {
    try {
        return fetch(`/api/minutes_settings`, {
            method: 'POST',
            headers: getPostHeaders(),
            body
        }).then(() => {
            //TODO: handle erros
        })
    } catch (error) {
        throw new Error(i18n.t('AI_REWRITE_ERROR'))
    }
}

export const patchNotificationSettings = async (body, minutesId) => {
    try {
        return fetch(`/api/minutes_settings/${minutesId}`, {
            method: 'PATCH',
            headers: getPostHeaders(),
            body
        }).then(() => {
            //TODO: handle erros
        })
    } catch (error) {
        throw new Error(i18n.t('AI_REWRITE_ERROR'))
    }
}
