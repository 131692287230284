import React from 'react'

const AILabelIcon = () => {
    return (
        <>
            <svg
                style={{ animation: 'rotate 2s linear infinite' }}
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M20 12C20 16.4183 16.4183 20 12 20C7.58172 20 4 16.4183 4 12C4 7.58172 7.58172 4 12 4C16.4183 4 20 7.58172 20 12ZM6.4 12C6.4 15.0928 8.90721 17.6 12 17.6C15.0928 17.6 17.6 15.0928 17.6 12C17.6 8.90721 15.0928 6.4 12 6.4C8.90721 6.4 6.4 8.90721 6.4 12Z"
                    fill="#D5E3F1"
                />
                <path
                    d="M12 4C13.5823 4 15.129 4.46919 16.4446 5.34824C17.7602 6.22729 18.7855 7.47672 19.391 8.93853C19.9965 10.4003 20.155 12.0089 19.8463 13.5607C19.5376 15.1126 18.7757 16.538 17.6569 17.6569C16.538 18.7757 15.1126 19.5376 13.5607 19.8463C12.0089 20.155 10.4003 19.9965 8.93853 19.391C7.47672 18.7855 6.22729 17.7602 5.34824 16.4446C4.46919 15.129 4 13.5823 4 12H6.4C6.4 13.1076 6.72843 14.1903 7.34377 15.1112C7.95911 16.0321 8.83371 16.7499 9.85697 17.1737C10.8802 17.5976 12.0062 17.7085 13.0925 17.4924C14.1788 17.2763 15.1766 16.743 15.9598 15.9598C16.743 15.1766 17.2763 14.1788 17.4924 13.0925C17.7085 12.0062 17.5976 10.8802 17.1737 9.85697C16.7499 8.83371 16.0321 7.95911 15.1112 7.34377C14.1903 6.72843 13.1076 6.4 12 6.4V4Z"
                    fill="url(#paint0_linear_1710_24315)"
                />
                <defs>
                    <linearGradient
                        id="paint0_linear_1710_24315"
                        x1="4.16438"
                        y1="4"
                        x2="7.62015"
                        y2="22.1265"
                        gradientUnits="userSpaceOnUse">
                        <stop stop-color="#D3222A" />
                        <stop offset="0.471283" stop-color="#B646F6" />
                        <stop offset="1" stop-color="#4D1DD5" />
                    </linearGradient>
                </defs>
            </svg>
            <style>
                {`
                @keyframes rotate {
                    0% {
                    transform: rotate(0deg);
                    }
                    100% {
                    transform: rotate(360deg);
                    }
                }
                `}
            </style>
        </>
    )
}

export default AILabelIcon
