import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
    InviteReviewers,
    getAdminContacts,
    getCommitteContacts,
    getCurrentReviewDetails
} from 'businesslayer/networkrequests'
import { inviteReviewers } from 'businesslayer/api/reviewInvitation'
import blockingOperation from 'businesslayer/blockingOperation'
import EmailNotification from './steps/email-step/EmailNotification'
import GeneralReviewer from './steps/reviewers-step/GeneralReviewer'
import { INVITEE_CONSTANTS, UseInvitationViewProps } from './types'
import {
    DataTemplate,
    STEPS,
    mapReviewersToInvities,
    prepareContacts,
    checkEditedReviews,
    removeDuplicates,
    evaluateDates,
    changeDueDateValue,
    validate,
    prepareDataForSections
} from './util'
import DueDateForm from './steps/date-step/DueDateForm'
import { calculateRestrictedPendoValues, pendoTrack } from 'common/util/functions'
import { pendoEvents } from 'common/util/pendoEvents'
import containsHTML from 'components/minutetaker/components/Utils/Utils'
const initialValue = {
    due_date_fields: 'no_due_date',
    recipients: [
        {
            name: '',
            email: '',
            review_id: '',
            section_ids: '',
            due_date: '',
            is_general: true,
            text: '',
            id: '',
            value: ''
        }
    ]
}

const useInvitationView = (props: UseInvitationViewProps) => {
    const {
        minuteTakerState,
        dataState,
        minutesId,
        onClose,
        updateReviewers,
        platform,
        reviewersList,
        setLoaded
    } = props
    const [chipOptions, setChipOptions] = useState<PersonChip[]>([])
    const [errorObject, setErrorObject] = useState<boolean>(false)
    const [initialValues, setInitialValues] = useState<InitialValue>()
    const [stepTwoError, setStepTwoError] = useState<DueDateError>({} as DueDateError)
    const [formValues, setFormValues] = useState<DueDateFormValues>()
    const [canFetch, setCanFetch] = useState<boolean>(true)
    const [activeStep, setActiveStep] = useState<number>(0)
    const [adminList, setAdminList] = useState<number[]>([])
    const [initialRecepients, setInitialRecepients] = useState<any[]>()
    const [canUpdate, setCanUpdate] = useState<boolean>(false)
    const [showHasHtmlMessage, setShowHasHtmlMessage] = useState({'body': false, 'subject': false});
    
    useEffect(() => {
        let receipentsDetails = JSON.parse(JSON.stringify(minuteTakerState.reviewers.recipients))

        const sameDate = evaluateDates(receipentsDetails)

        const dueDateFields =
            sameDate === null
                ? INVITEE_CONSTANTS.NO_DUE_DATE
                : sameDate
                ? INVITEE_CONSTANTS.SAME_DUE_DATE
                : INVITEE_CONSTANTS.DIFFERENT_DUE_DATE

        receipentsDetails = changeDueDateValue(receipentsDetails, dueDateFields)
        setInitialValues({
            ...initialValue,
            recipients: receipentsDetails,
            due_date_fields: dueDateFields,
            ...formValues
        })
    }, [formValues, minuteTakerState.reviewers.recipients])

    const updateAdminUsers = useCallback(() => {
        minuteTakerState.reviewers.recipients.forEach((contact) => {
            if (adminList.includes(contact.id) && !contact.is_general && contact.review_id) {
                contact.section_ids = dataState.minutesSections.map((item) => Number(item.id))
                updateReviewers(prepareDataForSections(contact, minuteTakerState))
            }
        })
    }, [minuteTakerState, updateReviewers, adminList, dataState])

    const fetchContacts = useCallback(
        async (reviewersList) => {
            blockingOperation.showProgress(null, true)
            const data = await getCommitteContacts(dataState.committeeId)
            setChipOptions(prepareContacts(data, reviewersList))
            const adminsData = await getAdminContacts(dataState.committeeId)
            setAdminList(adminsData.data.map((item) => Number(item.id)))
            blockingOperation.hideProgress()
            setCanUpdate(true)
        },
        [dataState.committeeId]
    )

    const fetchReviewDetails = useCallback(() => {
        getCurrentReviewDetails(dataState?.committeeId, minutesId)
            .then((res) => {
                const getReviewsData = mapReviewersToInvities(res, minuteTakerState, dataState)
                updateReviewers(getReviewsData)
                setInitialRecepients(getReviewsData.recipients)
                if (!chipOptions.length) fetchContacts(res.data.reviews)
                else blockingOperation.hideProgress()
            })
            .catch(() => {
                fetchContacts(minuteTakerState.reviewers.recipients)
                blockingOperation.hideProgress()
            })
    }, [dataState, minutesId, minuteTakerState, updateReviewers, chipOptions, fetchContacts])

    const handleClose = useCallback(() => {
        updateReviewers({ ...DataTemplate })
        onClose()
        setLoaded(true)
        setCanFetch(true)
    }, [updateReviewers, onClose, setLoaded])

    useEffect(() => {
        if (canFetch) {
            updateReviewers({ ...DataTemplate })
            fetchReviewDetails()
            setCanFetch(false)
        }
    }, [fetchReviewDetails, canFetch, updateReviewers])

    useEffect(() => {
        if (canUpdate) {
            updateAdminUsers()
            setCanUpdate(false)
        }
    }, [updateAdminUsers, canUpdate])

    const allowNextClick = useMemo(() => {
        const { recipients, notification_send, body, subject } = minuteTakerState.reviewers
        const hasUnselectedsections = recipients.some((it) => (it.section_ids?.length ?? 0) < 2)

        switch (activeStep) {
            case STEPS.GENERAL_REVIEWER:
                if (recipients.length && !hasUnselectedsections) {
                    return true
                }
                return false

            case STEPS.DUE_DATE_STEP:
                return true

            case STEPS.EMAIL_NOTIFICATION:
                if (notification_send && !!body.trim().length && !!subject.trim().length) {
                    return true
                }
                return notification_send ? false : true

            default:
                return true
        }
    }, [minuteTakerState.reviewers, activeStep])

    const handleNextClick = useCallback(() => {
        if (activeStep === 0) {
            const obj = formValues?.sameDueDate
                ? {
                      sameDueDate: formValues?.sameDueDate
                  }
                : undefined
            setFormValues(obj)
        }
        if (!allowNextClick) {
            setErrorObject(true)
        } else setActiveStep((pre) => pre + 1)
    }, [allowNextClick, activeStep, formValues])

    const handlePrevClick = useCallback(() => setActiveStep((pre) => pre - 1), [])

    const onHandleSubmitForm = useCallback(
        (values) => {
            const dueDateFormError = validate(values)
            let editedReviews: number[] = []
            setStepTwoError(dueDateFormError)

            if (dueDateFormError.recipients.length === 0 && !dueDateFormError.sameDueDate) {
                let updatedValues: PersonChip[]
                switch (values.due_date_fields) {
                    case INVITEE_CONSTANTS.SAME_DUE_DATE:
                        updatedValues = values.recipients.map((obj) => ({
                            ...obj,

                            due_date: values.sameDueDate?.format(
                                INVITEE_CONSTANTS.DATE_FORMAT_EMAIL
                            )
                        }))
                        break
                    case INVITEE_CONSTANTS.NO_DUE_DATE:
                        updatedValues = values.recipients.map((obj) => ({ ...obj, due_date: null }))
                        break
                    case INVITEE_CONSTANTS.DIFFERENT_DUE_DATE:
                        updatedValues = values.recipients.map((obj) => ({
                            ...obj,

                            due_date: obj?.due_date
                                ? obj?.due_date?.format(INVITEE_CONSTANTS.DATE_FORMAT_EMAIL)
                                : null
                        }))
                        break
                    default:
                        updatedValues = values.recipients
                }
                if (initialRecepients && initialRecepients?.length > 0) {
                    editedReviews = checkEditedReviews(initialRecepients, updatedValues)
                }
                let mySet: Set<unknown> = new Set([
                    ...minuteTakerState.reviewers.edited_reviews,
                    ...editedReviews
                ])
                let uniqueEditedViews: unknown[] = removeDuplicates(mySet)

                const revdata = {
                    ...minuteTakerState.reviewers,
                    recipients: updatedValues,
                    edited_reviews: [...uniqueEditedViews]
                }

                updateReviewers(revdata)

                setFormValues(values)
                handleNextClick()
            }
        },
        [handleNextClick, minuteTakerState, updateReviewers, initialRecepients]
    )
    const handleSubmit = useCallback(async () => {
        if (!allowNextClick) {
            setErrorObject(true)
            return
        }
        const payload = {
            ...minuteTakerState.reviewers,
            parent_platform: platform
        } as InviteReviewers
        try {
            blockingOperation.showProgress(null, true)
            const data = await inviteReviewers(minutesId, payload)
            if (data?.success) {
                const restrictedPendoObj = calculateRestrictedPendoValues(
                    reviewersList,
                    payload,
                    dataState.minutesSections.length
                )
                pendoTrack(pendoEvents.RESTRICTED_REVIEW_DETAILS, restrictedPendoObj)
                handleClose()
            }
            blockingOperation.hideProgress()
        } catch (error) {
            blockingOperation.hideProgress()
        }
    }, [
        minuteTakerState,
        handleClose,
        minutesId,
        platform,
        allowNextClick,
        reviewersList,
        dataState.minutesSections.length
    ])

    const validateAndSubmitReviewers = useCallback(() => {
        const { body, subject } = minuteTakerState.reviewers
        const bodyHasHTML = containsHTML(body)
        const subjectHasHTML = containsHTML(subject)

        if (bodyHasHTML || subjectHasHTML) {
            setShowHasHtmlMessage({ body: bodyHasHTML, subject: subjectHasHTML })
            setErrorObject(true)
        } else {
            setShowHasHtmlMessage({ body: false, subject: false })
            handleSubmit()
        }
    }, [minuteTakerState.reviewers, setShowHasHtmlMessage, handleSubmit])

    const getStepContent = useCallback(
        (step: number) => {
            switch (step) {
                case STEPS.GENERAL_REVIEWER:
                    return (
                        <GeneralReviewer
                            minuteTakerState={minuteTakerState}
                            updateReviewers={updateReviewers}
                            dataState={dataState}
                            chipOptions={chipOptions}
                            errorObject={errorObject}
                            setErrorObject={setErrorObject}
                            adminList={adminList}
                        />
                    )
                case 1:
                    return (
                        <DueDateForm
                            stepTwoError={stepTwoError}
                            initialValues={initialValues}
                            setStepTwoError={setStepTwoError}
                            onHandleSubmitForm={onHandleSubmitForm}
                            recepients={minuteTakerState.reviewers.recipients}
                        />
                    )
                case 2:
                    return (
                        <EmailNotification
                            minuteTakerState={minuteTakerState}
                            updateReviewers={updateReviewers}
                            chipOptions={chipOptions}
                            errorObject={errorObject}
                            setErrorObject={setErrorObject}
                            dataState={dataState}
                            showHasHtmlMessage={showHasHtmlMessage}
                            setShowHasHtmlMessage={setShowHasHtmlMessage}
                        />
                    )
                default:
                    return ''
            }
        },
        [
            minuteTakerState,
            updateReviewers,
            dataState,
            chipOptions,
            errorObject,
            adminList,
            stepTwoError,
            initialValues,
            onHandleSubmitForm,
            setStepTwoError,
            showHasHtmlMessage
        ]
    )

    return {
        activeStep,
        handleClose,
        handleNextClick,
        handlePrevClick,
        getStepContent,
        chipOptions,
        allowNextClick,
        errorObject,
        setErrorObject,
        validateAndSubmitReviewers
    }
}

export default useInvitationView
