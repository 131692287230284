//#region Components
import React from 'react'
import styled from '@emotion/styled'
import { css, ClassNames } from '@emotion/react'

import { translate } from 'components/shared/internationalization'
import i18n from 'i18next'
import { Form, Field } from 'react-final-form'
import { SelectSectionField } from 'components/minutetaker/components/form/SelectSectionField'
import { AssigneeChipInputField } from 'components/minutetaker/components/form/AssigneeChipInputField'
import { DateField } from '../form/DateField'

import { getExistingAssigneesData } from '../../actionlist/listeditors/EditAssigneesDialog'
import { RootRef, NewDialogTextField } from 'components/shared/StyledComponents'
import { format, toDate, isValid, parse } from 'date-fns'
import moment from 'moment'
import TextLengthCounter from 'components/shared/TextLengthCounter'
import { InputLabelStyled } from 'components/minutetaker/components/form/AssigneeChipInputField'
import { Box, Checkbox, makeStyles, Typography } from '@material-ui/core'
import { useMinutesManager } from 'pages/minutes-manager-page/use-minutes-manager'
import { getSessionStorageItem } from 'businesslayer/minutesLocalStore'
import { ColorBaseWhite } from 'assets/styles/variables'

//#region Props / State
const MAX_SUBJECT = 10000

type Props = {
    action: any
    allContacts: any
    dateInputMask: any
    sections: any
    selectedSection: any
    onActionItemSubmit: any
    onValidate: any
    isAdmin: boolean
}
const useStyles = makeStyles(() => ({
    checkbox: {
        padding: '0px',
        '&.Mui-checked': {
            color: '#2F3B4D'
        },
        '&.Mui-disabled': {
            color: 'rgba(0, 0, 0, 0.26)'
        },
        marginRight: '8px',
        width: '24px',
        height: '24px',
        '&.Mui-focusVisible, &:active': {
            boxShadow: `0px 0px 0px 3px #0774EE, 0px 0px 0px 1px ${ColorBaseWhite}`,
            borderRadius: '4px'
        },
        color: '#1e1e1e'
    },
    checkBoxContainer: {
        fontFamily: 'Source Sans Pro',
        fontSize: '13px',
        fontWeight: 400,
        lineHeight: '16px',
        letterSpacing: '0px',
        textAlign: 'left',
        paddingBottom: '8px',
        placeItems: 'center',
        display: 'flex',
        paddingTop: '1rem'
    },
    checkboxTitle: {
        cursor: 'pointer',
        fontFamily: 'Source Sans Pro',
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '18px',
        letterSpacing: '0px',
        textAlign: 'left',
        padding: '0px',
        color: '#000000'
    }
}))

type State = {}

function WrapComponent(Component) {
    return function WrappedComponent(props) {
        const { isAdminOfCommittee } = useMinutesManager()
        const isAdmin = isAdminOfCommittee(getSessionStorageItem('currentCommitteeId'))
        return <Component {...props} isAdmin={isAdmin} />
    }
}
class ActionsItemEditForm extends React.Component<Props, State> {
    state = {
        showSaveButton: true,
        maxLength: 0,
        actionTextLength: 0
    }
    actionText?: HTMLElement

    componentDidMount() {
        this.setState({ maxLength: this.props.action.text.length })
        this.setState({ actionTextLength: this.props.action.text.length })
        setTimeout(() => {
            if (this.actionText) {
                this.actionText.focus()
            }
        }, 5)
    }

    checkSectionExists = (selectedSectionId, sections) => {
        return sections.findIndex((section) => selectedSectionId === section.id) > -1
    }

    getPreselectedSectionId = ({ action, selectedSection, sections }): string => {
        //We preselect current section in the drop down if possible
        let selectedSectionId = selectedSection
            ? this.checkSectionExists(selectedSection.id, sections)
                ? selectedSection.id
                : ''
            : ''

        const valueId = action.minutesSectionId ? `${action.minutesSectionId}` : selectedSectionId
        return valueId
    }

    inputDateToDate = (date, format) => {
        return isValid(date) ? toDate(date) : parse(date, format, new Date())
    }

    handleValidation = (values) => {
        let fieldErrors = {}

        // Title
        let field = 'action_title'
        if (!(typeof values[field] === 'string' && values[field].trim().length > 0)) {
            fieldErrors[field] = translate('INVALID_ACTION_TEXT')
        }
        // Section
        field = 'action_section'
        if (!(typeof values[field] === 'string' && values[field].trim().length > 0)) {
            fieldErrors[field] = translate('INVALID_ACTION_SECTION')
        }
        // Date

        field = 'action_date'
        // '1899-12-30', '2100-01-01' are min and max dates defined by mui-picker
        if (
            values[field] !== '' &&
            values[field] !== null &&
            values[field] !== undefined &&
            !moment(values[field], this.props.dateInputMask)
                .utc()
                .isBetween('1899-12-30', '2100-01-01', 'day')
        ) {
            fieldErrors[field] = true
        }

        if (
            !isValid(parse(values[field], this.props.dateInputMask, new Date())) &&
            !isValid(values[field]) &&
            values[field] !== '' &&
            values[field] !== null &&
            values[field] !== undefined
        ) {
            const dateMask = this.props.dateInputMask.toUpperCase()
            fieldErrors[field] = i18n.t('INVALID_MINUTES_DATE_FORMAT', { mask: dateMask })
        }
        if (
            Object.keys(fieldErrors).length === 0 &&
            fieldErrors.constructor === Object &&
            this.state.maxLength <= 10000
        ) {
            this.props.onValidate(true)
        } else {
            this.props.onValidate(false)
        }
        return fieldErrors
    }

    handleSubmit = ({ action, values }) => {
        action.text = values.action_title
        // Text Date and Date Picker have different formats
        action.dueDate = !!values.action_date
            ? isValid(values.action_date)
                ? toDate(values.action_date)
                : parse(values.action_date, this.props.dateInputMask, new Date())
            : null
        action.minutesSectionId = values.action_section
        action.assignees = values.action_assignees.map((it) => ({
            ...it,
            self_manage: values.self_manage
        }))
        this.props.onActionItemSubmit(action)
    }

    render() {
        const { action, selectedSection, dateInputMask, sections, isAdmin } = this.props
        const valueId = this.getPreselectedSectionId({ action, selectedSection, sections })
        const dueDateObject = action.dueDate ? format(action.dueDate, dateInputMask) : ''
        const asignees = getExistingAssigneesData(action)
        const initialValues = {
            action_title: !!this.props.action.text ? this.props.action.text : '',
            action_section: valueId,
            action_date: dueDateObject,
            action_assignees: asignees,
            self_manage: asignees?.[0]?.self_manage ?? true
        }

        return (
            <Form
                initialValues={initialValues}
                onSubmit={(values) => {
                    this.handleSubmit({ values, action })
                }}
                validate={(values) => this.handleValidation(values)}
                keepDirtyOnReinitialize={true}
                render={({ errors, handleSubmit, values }) => {
                    const classes = useStyles()
                    return (
                        <StyledForm id="editActionsForm" onSubmit={handleSubmit}>
                            <ActionTitleContainer>
                                <Field
                                    name="action_title"
                                    render={({
                                        input: { onChange, ...rest },
                                        meta: { touched }
                                    }) => {
                                        //this.setState({maxLength: rest.value.length})
                                        return (
                                            <ClassNames>
                                                {({ css }) => (
                                                    <div
                                                        className={css`
                                                            ${textFieldContainerClass}
                                                        `}>
                                                        <RootRef
                                                            rootRef={(text) =>
                                                                (this.actionText = text)
                                                            }>
                                                            <div
                                                                className={css`
                                                                    width: 100%;
                                                                `}>
                                                                <InputLabelStyled
                                                                    required
                                                                    data-testid="action-title">
                                                                    {translate('ACTION')}
                                                                </InputLabelStyled>
                                                                <div>
                                                                    <NewDialogTextField
                                                                        className={css`
                                                                            ${TextFieldStyles}
                                                                        `}
                                                                        multiline={true}
                                                                        variant="outlined"
                                                                        required
                                                                        error={
                                                                            !!touched &&
                                                                            !!errors &&
                                                                            !!errors.action_title
                                                                        }
                                                                        helperText={
                                                                            <TextLengthCounter
                                                                                errorText={
                                                                                    rest.value
                                                                                        .length >=
                                                                                    10001
                                                                                        ? i18n.t(
                                                                                              'MAX_LENGTH'
                                                                                          )
                                                                                        : touched
                                                                                        ? !!errors &&
                                                                                          errors.action_title
                                                                                        : ''
                                                                                }
                                                                                maxValue={
                                                                                    MAX_SUBJECT
                                                                                }
                                                                                value={rest.value}
                                                                                colour={true}
                                                                            />
                                                                        }
                                                                        onChange={(event) => {
                                                                            this.setState({
                                                                                showSaveButton: true,
                                                                                maxLength:
                                                                                    event.target
                                                                                        .value
                                                                                        .length
                                                                            })
                                                                            onChange(event)
                                                                        }}
                                                                        {...rest}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </RootRef>
                                                    </div>
                                                )}
                                            </ClassNames>
                                        )
                                    }}
                                    type="text"
                                />
                            </ActionTitleContainer>
                            <AssigneeChipInputField
                                isAdmin={isAdmin}
                                allContacts={this.props.allContacts}
                            />

                            {this.props.isAdmin && values.action_assignees?.length > 0 && (
                                <Field name="self_manage" type="checkbox">
                                    {({ input }) => (
                                        <Box className={classes.checkBoxContainer}>
                                            <Checkbox
                                                disableFocusRipple
                                                disableTouchRipple
                                                disableRipple
                                                className={classes.checkbox}
                                                checked={input.checked}
                                                onChange={() => input.onChange(!input.checked)}
                                                color="default"
                                                role="checkbox"
                                                aria-checked={input.checked ? 'true' : 'false'}
                                                tabIndex={0}
                                                inputProps={
                                                    {
                                                        'data-analytics': input.checked
                                                            ? 'MM-SelfManageCheckboxFromEditorEnable'
                                                            : 'MM-SelfManageCheckboxFromEditorDisable'
                                                    } as React.InputHTMLAttributes<HTMLInputElement>
                                                }
                                            />
                                            <Typography
                                                className={classes.checkboxTitle}
                                                onClick={() => input.onChange(!input.checked)}>
                                                {translate('SELF_MANAGE')}
                                            </Typography>
                                        </Box>
                                    )}
                                </Field>
                            )}
                            <DateSectionRow>
                                <DateField
                                    error={!!errors && errors.action_date}
                                    dateInputMask={dateInputMask}
                                />

                                <SelectSectionField
                                    name="action_section"
                                    sections={this.props.sections}
                                    selectedSection={valueId}
                                    isReadOnly={!!action.id}
                                    error={!!errors && errors.action_section}
                                />
                            </DateSectionRow>
                        </StyledForm>
                    )
                }}
            />
        )
    }
}

//#endregion

//#region Styles
const textFieldContainerClass = css`
    display: flex;
    align-items: flex-start;
    min-height: 70px;
    width: 100%;
`

const StyledForm = styled('form')``

const ActionTitleContainer = styled('div')`
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 20px;
`

const DateSectionRow = styled('section')``

const TextFieldStyles = css`
    .MuiInputBase-root.MuiOutlinedInput-root {
        min-height: 40px;
        margin-top: 8px;
    }
    p {
        margin-left: 0px;
        margin-right: 0px;
    }
`

//#endregion

export default WrapComponent(ActionsItemEditForm)
