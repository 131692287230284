import { getJSON } from 'common/util/request'
import { makeFetcher } from 'businesslayer/networkrequests/swr-util'
import useSWR from 'swr'
import { getPostHeaders } from 'common/util/requestUtils'

export const getBookDetails = (bookId, committeeId) => {
    return getJSON(`committees/${committeeId}/books/${bookId}`)
}

export default getBookDetails

export const useBookDetailsSWR = (
    bookId: string | number | null | undefined,
    committeeId: string | number | null | undefined,
    swrOptionsOverride?: ConfigInterface,
    fetcherOptionsOverride?: FetcherOptions
) => {
    const path = `committees/${committeeId}/books/${bookId}`
    const conditionalPath = !!bookId ? path : null
    const fetcherOptions = { shouldNormalize: true, ...fetcherOptionsOverride }

    const fetcher = makeFetcher(fetcherOptions)
    const result = useSWR(conditionalPath, fetcher, swrOptionsOverride)

    // If no bookId given, return undefined instead of cache
    if (!bookId) {
        return { ...result, data: undefined }
    }

    return result
}

export const getSummaryStatus = async (
    bookId: string | number,
    committeeId: string | number,
    committeeName: string
) => {
    try {
        const response = await fetch(
            `/api/committees/${committeeId}/books/${bookId}/summary_status?committee_name=${committeeName}`,
            {
                method: 'GET',
                headers: getPostHeaders()
            }
        )

        if (!response.ok) {
            console.error('Network response was not ok')
        }

        const data = await response.json()
        return data
    } catch (error) {
        console.error('Network response was not ok')
    }
}
