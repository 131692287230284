import React from 'react'
import i18n from 'i18next'
import moment from 'moment'
import { MetaDataList } from 'components/meta-data-column-list/meta-data-column-list'
import { transientStorageManager } from 'businesslayer/minutesSessionStore'
import { pathOr, merge, keys, pick, isEmpty, mergeAll, sort } from 'rambdax'
import { formatDateOr, getMomentTime, minutesOfDay } from 'common/formatters/date'
import { GetBookDetails } from 'components/minutemanager/requests/GetBookDetails'
import { PLACEHOLDER_KEY } from 'components/atlas-material/select'
import { isDateRowEmpty } from 'common/util/validators/minutes-properties/minutes-properties-validators'
import { PersonChip } from 'components/chip-input/types'
import { EditMode } from './use-minutes-properties-modal'
import {
    BookStructure,
    DEFAULT_BOOK_STRUCTURE_ITEM
} from 'components/popovers/linked-book-structure/linked-book-structure-items'

const DEFAULT_DUPLICATE_OPTION = 'sections_only' as DuplicateMinutesOption

export const EMPTY_MEETING_DATE: DateRow = {
    date: null,
    startTime: null,
    endTime: null,
    timezone: ''
}

export const EMPTY_ATTENDEES_MEETING_DATE: AttendesReportDateRow = {
    startDate: null,
    endDate: null
}
export const EMPTY_MEETING_DATE_KEYS = keys(EMPTY_MEETING_DATE)
export const EMPTY_MEETING_ATTENDEES_DATE_KEYS = keys(EMPTY_ATTENDEES_MEETING_DATE)

const EMPTY_FORM_VALUES: MinutesPropertiesFormValues = {
    id: null,
    duplicateOption: undefined,
    committee: { id: PLACEHOLDER_KEY, name: '' },
    title: '',
    include_logo: true,
    logo: null,
    logo_filename: null,
    logoFilename: null,
    logoType: 'DEFAULT_LOGO',
    date: null,
    startTime: null,
    endTime: null,
    timezone: '',
    meeting_dates: [],
    attendees: [],
    location: '',
    status: 'draft',
    bookId: null,
    bookTitle: '',
    bookStructure: 'NONE',
    editMode: ''
}

export const getUpdatedMinutesItem = (
    originalMinutesItem,
    formValues: MinutesPropertiesFormValues
) => {
    const dateFieldsRow = pick(EMPTY_MEETING_DATE_KEYS, formValues) as DateRow

    const parsedMeetingDates = parseDateRows(formValues.meeting_dates, dateFieldsRow)
    const attendees = parseAttendeesChips(formValues.attendees)
    const bookStructureDetails = getBookStructureDetails(formValues.bookStructure)
    const logoDetails = getLogoDetails(formValues)

    const parsedFormValues = {
        ...formValues,
        meeting_dates: parsedMeetingDates,
        attendees,
        ...bookStructureDetails,
        ...logoDetails
    }
    const updatedMinutesItem = merge(originalMinutesItem, parsedFormValues)

    return updatedMinutesItem
}

export const initializeFormValues = (
    minutesItem: Partial<MinutesUnnormalized> | undefined,
    editMode: EditMode
): MinutesPropertiesFormValues => {
    const currentUser = transientStorageManager.currentUser as Contact
    const attendeeChips = getAttendeesChips(minutesItem, currentUser)

    if (!minutesItem) {
        return { ...EMPTY_FORM_VALUES, attendees: attendeeChips }
    }

    const bookId = pathOr<string | number | null>(null, 'book.id', minutesItem)
    const isCreatingLinkedMinutes = !!bookId && editMode === 'CREATE'
    const isDuplicatingMinutes = editMode === 'DUPLICATE'
    // Derived Minutes Data for Form
    const duplicateOption = isDuplicatingMinutes ? DEFAULT_DUPLICATE_OPTION : undefined
    const bookTitle = pathOr<string>('', 'book.title', minutesItem)
    const bookStructure = isCreatingLinkedMinutes
        ? transientStorageManager.book_summarization_enabled
            ? 'AI_ASSIST'
            : DEFAULT_BOOK_STRUCTURE_ITEM
        : 'NONE'
    const minutesTitle = minutesTitleWithPrefix(isDuplicatingMinutes, bookTitle, minutesItem.title)
    const logoType = getLogoType(!!minutesItem.includeLogo, minutesItem.logo)
    // If creating minutes with linked book we will set the input fields for the date given
    const dateFields = isCreatingLinkedMinutes ? toDateRows(minutesItem.meeting_dates)[0] : {}
    // If not creating a minutes linked to a book we will display all the existing dates as rows
    const meetingDates = !isCreatingLinkedMinutes ? toDateRows(minutesItem.meetingDates) : []

    const pickMinutesAttribute = makePickMinutesAttibute(minutesItem)

    return mergeAll<MinutesPropertiesFormValues>([
        EMPTY_FORM_VALUES,
        pickMinutesAttribute('id'),
        pickMinutesAttribute('title'),
        pickMinutesAttribute('committee'),
        pickMinutesAttribute('include_logo'),
        pickMinutesAttribute('logo'),
        pickMinutesAttribute('logo_filename'),
        pickMinutesAttribute('logoFilename'),
        pickMinutesAttribute('location'),
        pickMinutesAttribute('status'),
        pickMinutesAttribute('editMode'),
        // Derived Minutes Data for Form
        {
            duplicateOption,
            title: minutesTitle,
            ...dateFields,
            meeting_dates: meetingDates,
            attendees: attendeeChips,
            bookId,
            bookTitle,
            bookStructure,
            logoType,
            editMode
        }
    ])
}

const makePickMinutesAttibute = (minutesItem: Partial<MinutesUnnormalized>) => <
    A extends keyof MinutesPropertiesFormValues
>(
    attirbute: A
) =>
    pick<Partial<MinutesUnnormalized>, Pick<MinutesPropertiesFormValues, A>>(attirbute, minutesItem)

const getAttendeesChips = (
    minutesItem: Partial<MinutesUnnormalized> | undefined,
    currentUser: Contact | undefined
) => {
    // Note that 'external_id' here is not the ID from the parent platform;
    // it's the contact ID, which is quite confusing, but a bug in the database
    // implementation. currentUser.id holds the user's Contact ID but we must store
    // it in the column 'external_id' in table 'minutes_documents_contacts' in the database.
    const currentUserAsAttendee: Attendee | undefined = !!currentUser
        ? { ...currentUser, external_id: currentUser.id }
        : undefined
    const defaultAttendees = !!currentUserAsAttendee ? [currentUserAsAttendee] : []

    const minutesAttendees = pathOr<Attendee[]>([], 'attendees', minutesItem)
    const attendeesForForm = isEmpty(minutesAttendees) ? defaultAttendees : minutesAttendees

    return attendeesToChipData(attendeesForForm)
}

const parseAttendeesChips = (personChipsData: PersonChip[]) => {
    if (!personChipsData) return []
    return personChipsData.map((chipData) => {
        return {
            display_name: chipData.text,
            email: chipData.email,
            external_id: chipData.id || null
        }
    })
}
const attendeesToChipData = (attendees: Attendee[] | undefined): PersonChip[] => {
    if (!attendees) return []
    return attendees.map((attendee) => {
        return {
            text: attendee.display_name,
            email: attendee.email,
            /*
            This handles the case of a user-created attendee in the Attendees
            field; that attendee will not have an "external_id", which,
            despite its name, is the contact.id value from our database,
            not the parent platform's external_id.
            */
            id: attendee.external_id || null,
            value: attendee.display_name
        }
    }) as PersonChip[]
}

export const minutesTitleWithPrefix = (
    isDuplicating: boolean,
    bookTitle: string | undefined,
    minutesTitle: string | undefined
) => {
    if (isDuplicating) return i18n.t('DUPLICATE_NAME_PREFIX') + (minutesTitle || '')
    if (!!bookTitle) return i18n.t('LINKED_MINUTES_DEFAULT_TITLE', { bookName: bookTitle })

    return minutesTitle || ''
}

export const parseDateRows = (meetingDates: DateRow[], dateFieldsRow: DateRow) => {
    // If the user left a values in the date fields before adding them
    // then lets add the meeting date to the list
    const meetingDateList = isDateRowEmpty(dateFieldsRow)
        ? meetingDates
        : [...meetingDates, dateFieldsRow]

    return meetingDateList.map((dateRow) => {
        const { date, startTime, endTime, timezone } = dateRow
        const startDate = !!date ? date.toISOString() : null
        const startHour = !!startTime ? startTime.hour() : null
        const startMinute = !!startTime ? startTime.minute() : null
        const endHour = !!endTime ? endTime.hour() : null
        const endMinute = !!endTime ? endTime.minute() : null
        return {
            end_date: startDate,
            end_hour: endHour,
            end_minute: endMinute,
            start_date: startDate,
            start_hour: startHour,
            start_minute: startMinute,
            time_zone_code: timezone
        }
    })
}

export const toDateRows = (meetingDatesDetails: MeetingDate[] | undefined): DateRow[] => {
    if (!meetingDatesDetails) return []

    const meetingDateRows = meetingDatesDetails.map<DateRow>((dateDetails) => {
        const { start_date, start_hour, start_minute, end_hour, end_minute, time_zone_code } =
            dateDetails ?? {}
        const startDate = !!start_date ? moment(start_date) : null
        const startTime = getMomentTime(start_hour, start_minute)
        const endTime = getMomentTime(end_hour, end_minute)
        return {
            date: startDate,
            startTime: startTime,
            endTime: endTime,
            timezone: time_zone_code || ''
        }
    })

    return sortMeetingDateRows(meetingDateRows)
}

export const sortMeetingDateRows = (DateRowList) => {
    const byDateAsc = (a: DateRow, b: DateRow) => {
        if (moment(a.date).isSame(b.date, 'day')) {
            return minutesOfDay(a.startTime) > minutesOfDay(b.startTime) ? 1 : -1
        }

        return moment(a.date).isAfter(b.date, 'day') ? 1 : -1
    }

    return sort(byDateAsc, DateRowList)
}

const getBookStructureDetails = (bookStructure: BookStructure) => {
    const structureDetailsMap = {
        AI_ASSIST: {
            pregenerated_minutes: true,
            include_book_structure: false,
            include_sub_tabs: false
        },
        NONE: {
            pregenerated_minutes: false,
            include_book_structure: false,
            include_sub_tabs: false
        },
        TOP_LEVEL: {
            pregenerated_minutes: false,
            include_book_structure: true,
            include_sub_tabs: false
        },
        ENTIRE_BOOK: {
            pregenerated_minutes: false,
            include_book_structure: true,
            include_sub_tabs: true
        }
    }

    return (
        structureDetailsMap[bookStructure] || {
            include_book_structure: false,
            include_sub_tabs: false
        }
    )
}

type LogoDetails = Pick<MinutesPropertiesFormValues, 'logo_filename' | 'logo' | 'include_logo'>
const getLogoDetails = (values: MinutesPropertiesFormValues): LogoDetails => {
    const logoType = values.logoType
    if (logoType === 'NONE') return { logo_filename: null, logo: null, include_logo: false }

    if (logoType === 'DEFAULT_LOGO') return { logo_filename: null, logo: null, include_logo: true }

    if (logoType === 'UPLOAD_LOGO')
        return { logo_filename: values.logo_filename, logo: values.logo, include_logo: false }

    // Fallback
    return { logo_filename: null, logo: null, include_logo: true }
}
const getLogoType = (isDefaultLogo: boolean, logo: string | undefined | null): LogoOption => {
    if (isDefaultLogo) return 'DEFAULT_LOGO'

    if (logo) return 'UPLOAD_LOGO'

    return 'NONE'
}

// = = = = = = = = = =
// Minutes Meta Data
// = = = = = = = = = =
export const getMinutesMetaData = (minutes): MetaDataList => {
    if (!minutes) return []

    const { dateFormat } = transientStorageManager

    const NO_DATE = i18n.t('NO_DUE_DATE')
    const author = pathOr('', 'author.display_name', minutes)
    const createdDate = formatDateOr(NO_DATE, 'createdAt', minutes, dateFormat)
    const updatedDate = formatDateOr(NO_DATE, 'updated_at', minutes, dateFormat)
    const linkedBookId = pathOr(null, 'book.id', minutes)
    const linkedBookTitle = !!linkedBookId
        ? loadLinkedBookTitle(linkedBookId)
        : i18n.t('NOT_LINKED')

    return [
        { key: i18n.t('DATE_CREATED'), value: createdDate },
        { key: i18n.t('UPDATED_LABEL'), value: updatedDate },
        { key: i18n.t('MEETING_AUTHOR'), value: author },
        { key: i18n.t('LINKED_TO'), value: linkedBookTitle }
    ]
}

const mapLinkedToFromResponse = ({ books }) => {
    const bookObject = books[Object.keys(books)[0]]
    const bookName: string = pathOr('', ['attributes', 'title'], bookObject)

    return bookName
}

const loadLinkedBookTitle = (bookId) => (
    <GetBookDetails bookId={bookId}>
        {({ isLoading, isSuccess, isError, response }) => (
            <>
                {isLoading && <span>{i18n.t('LOADING')}</span>}
                {isError && <span>{i18n.t('LINKED_TO_BOOK_UNAVAILABLE')}</span>}
                {isSuccess && <span>{mapLinkedToFromResponse(response)}</span>}
            </>
        )}
    </GetBookDetails>
)
