import React from 'react'
import styled from '@emotion/styled'
import { css, ClassNames } from '@emotion/react'
import {
    ColorBaseGreyDark,
    ColorBaseGreyLight,
    ColorBaseMulberryBlue
} from 'assets/styles/variables'
import { Body } from 'components/shared/typography/Typography'
import { Checkbox } from 'components/shared/StyledComponents'
import { MoveSectionIcon as MoveActionIcon } from 'assets/icons/MoveSectionIcon'
import { makeStyles } from '@material-ui/core/styles'
import i18n from 'i18next'
const useStyles = makeStyles({
    root: {
        '& input:focus ~ .MuiSvgIcon-root': {
            boxShadow: `0px 0px 0px 3px ${ColorBaseMulberryBlue}`
        }
    }
})

export default function MinuteActionItemView(props: Props) {
    const {
        isReadonly,
        actionItem,
        onActionChecked,
        onContextMenu,
        onClick,
        isDroppingOver,
        previewHeight,
        snapshot,
        dragProps
    } = props

    /**
     * Now that we are triggering the modal on click of the title.
     *
     * We must prevent the click event from bubbling up from checkbox
     * and triggering the modal
     *
     * @param e
     *
     */
    const classes = useStyles()
    const handleActionChecked = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault()
        e.stopPropagation()
        onActionChecked(e)
    }
    const dragStyle = snapshot.isDragging
        ? {
              border: `2px solid ${ColorBaseMulberryBlue}`,
              padding: '6px',
              borderRadius: '40px',
              outline: 'none',
              cursor: 'pointer'
          }
        : {}

    return !!isReadonly ? (
        <ClassNames>
            {({ css }) => (
                <div
                    className={css`
                        ${readonlyActionItemContainerClass}
                    `}>
                    <div
                        className={css`
                            ${verticalSeparatorClass}
                        `}
                    />
                    <div
                        className={css`
                            ${actionContentsClass}
                        `}>
                        <div
                            className={css`
                                ${actionItemTitleReadOnly}
                            `}>
                            <p>
                                <StyledActionTitle
                                    aria-label={actionItem.text}
                                    type="body1"
                                    className={`${actionstyle}`}>
                                    {actionItem.text}
                                </StyledActionTitle>
                            </p>
                        </div>
                    </div>
                </div>
            )}
        </ClassNames>
    ) : (
        <ClassNames>
            {({ css }) => (
                <div
                    className={css`
                        ${actionItemContainerClass(isDroppingOver, previewHeight)}
                    `}
                    onContextMenu={onContextMenu}>
                    <div
                        className={css`
                            ${verticalSeparatorClass}
                        `}
                    />
                    <div
                        className={css`
                            ${actionContentsClass}
                        `}>
                        <div
                            className={css`
                                ${actionTitleClass}
                            `}
                            onClick={onClick}>
                            <Checkbox
                                className={css`
                                    ${actionCheckboxClass}
                                `}
                                classes={{ root: classes.root }}
                                checked={actionItem.completionStatus === 'complete'}
                                onClick={handleActionChecked}
                                disableFocusRipple
                                disableRipple
                                inputProps={
                                    {
                                        'data-analytics':
                                            actionItem.completionStatus === 'complete'
                                                ? 'MM-ActionCompletionActionPageEnable'
                                                : 'MM-ActionCompletionActionPageDisable',

                                        'aria-label': 'Action Check',
                                        'aria-checked':
                                            actionItem.completionStatus === 'complete'
                                                ? 'true'
                                                : 'false'
                                    } as React.InputHTMLAttributes<HTMLInputElement>
                                }
                            />

                            <p>
                                <StyledActionTitle
                                    aria-label={actionItem.text}
                                    type="body1"
                                    className={`${actionstyle}`}>
                                    {actionItem.text}
                                </StyledActionTitle>
                            </p>
                        </div>

                        <div style={{ dragStyle }} {...dragProps} className="draggableicon">
                            <div
                                aria-label={i18n.t('DRAGGABLE_ICON')}
                                style={{ paddingBottom: '2px' }}
                                role="button">
                                <StyledMoveActionIcon />
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </ClassNames>
    )
}

type Props = {
    isReadonly?: boolean
    actionItem: ActionItem
    previewHeight: number
    isDroppingOver: boolean
    onActionChecked: (...args: any[]) => void
    onContextMenu: (...args: any[]) => void
    onClick: (...args: any[]) => void
    snapshot: any
    dragProps: any
}

const actionItemContainerClass = (isDroppingOver, previewHeight) => css`
    display: flex;
    flex-direction: column;
    cursor: pointer;
    border-top: ${isDroppingOver ? previewHeight + 'px solid #dff2f9' : ColorBaseGreyLight};

    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Chrome and Opera */
`
const readonlyActionItemContainerClass = css`
    display: flex;
    flex-direction: column;
    /* &:hover {
        background-color: rgba(230, 240, 242, 0.5);
    } */
    border-top: ${ColorBaseGreyLight};
`

const verticalSeparatorClass = css`
    width: 100%;
`

const actionContentsClass = css`
    display: flex;
    align-items: center;
    flex-direction: row;
    margin: 5px 0px 5px 48px;
    justify-content: space-between;
    padding-bottom: 5px;
    border-bottom: 1px solid #d7dde6;
`

const actionTitleClass = css`
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #385f99;
`
const actionItemTitleReadOnly = css`
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #1e1e1e;
`
const actionstyle = css`
    align-items: center;
    width: auto;
    height: 100px;
    word-break: break-word;
    white-space: normal;
`

const actionCheckboxClass = css`
    padding: 0px;
    margin-right: 15px;
`

const StyledActionTitle = styled(Body)`
    padding-top: 10px;
    width: 100%;
    word-break: break-word;
    white-space: normal;
    hyphens: auto;
    padding-bottom: 10px;
    font-family: Source Sans Pro;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
`

const StyledMoveActionIcon = styled(MoveActionIcon)`
    margin-top: 4px;
    fill: ${ColorBaseGreyDark};
`
