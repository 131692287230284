import React, { useState, useCallback, useMemo } from 'react'
import i18n from 'i18next'
import { MinutesPropertiesModal } from './minutes-properties-modal'
import { useLocation } from 'react-router-dom'

export type EditMode = 'CREATE' | 'EDIT' | 'DUPLICATE'
type Props = {
    canAddMinutes: boolean
    onClose?: () => void
    onSave: ({ updatedMinutesItem }: { updatedMinutesItem: MinutesUnnormalized }) => void
}

export const useMinutesPropertiesModal = ({ canAddMinutes, onClose, onSave }: Props) => {
    const [showManagerDialog, setShowManagerDialog] = useState(false)
    const [reason, setReason] = useState<EditMode>('CREATE')
    const [minutesItem, setMinutesItem] = useState<Partial<MinutesUnnormalized> | undefined>(
        undefined
    )
    const location = useLocation()
    const queryParams = useMemo(() => new URLSearchParams(location.search), [location.search])
    const [bookId] = useState(queryParams.get('bookId') || '')

    const handleOpenMinutesModal = useCallback(
        (reason: EditMode, minutesItem?: Partial<MinutesUnnormalized>) => {
            setReason(reason)
            setMinutesItem(minutesItem)
            canAddMinutes && setShowManagerDialog(true)
        },
        [canAddMinutes]
    )

    const handleCloseMinutesEditor = () => {
        !!onClose && onClose()
        setShowManagerDialog(false)
        setMinutesItem(undefined)
    }

    const handleSaveMinutesItem = ({ updatedMinutesItem }) => {
        onSave({ updatedMinutesItem })
        setShowManagerDialog(false)
        setMinutesItem(undefined)
    }

    const title = getModalTitle(reason)

    const Modal = showManagerDialog ? (
        <MinutesPropertiesModal
            key="minutesPropertiesModal"
            isOpen={showManagerDialog}
            title={title}
            onCloseMinutesEditor={handleCloseMinutesEditor}
            onSaveMinutesItem={handleSaveMinutesItem}
            editMode={reason}
            minutesItem={minutesItem}
            bookId={bookId}
        />
    ) : null

    return { MinutesPropertiesModal: Modal, handleOpenMinutesModal }
}

const getModalTitle = (editMode: EditMode) => {
    if (editMode === 'CREATE') return i18n.t('CREATE_MEETING_MINUTES')
    if (editMode === 'EDIT') return i18n.t('EDIT_MINUTES_PROPERTIES')
    if (editMode === 'DUPLICATE') return i18n.t('DUPLICATE_MEETING_MINUTES')

    return i18n.t('CREATE_MEETING_MINUTES')
}
