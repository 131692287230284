import React from "react";
import "./Toast.css";
import SuccessIcon from "assets/icons/SuccessIcon";
import ToastErrorIcon from "assets/icons/ToastErrorIcon";
import i18n from "common/util/internationalization";
import applicationRouter from "businesslayer/applicationRouter";
import { updatePregenerationAIMinutes } from "businesslayer/networkrequests";
import { PREGENERATION_DOCUMENT_STATUS } from "components/minutetaker/components/InvitationView/types";
import { translate } from "components/shared/internationalization";

const StatusToast = ({ id, preGenPollingStatus, statusMessage, minuteInitiateUser, loggedInUser, MinutesTitle, removeToast }) => {
    const status_Message = i18n.t(statusMessage)

    return (
        <>  
            {/* If Logged in user is same as take minutes book initiator then show the toast */}
            {(minuteInitiateUser === loggedInUser) ?
                <div className={`custom-toast ${preGenPollingStatus}`}>
                    <div className="custom-toast-header">
                        <span className="custom-toast-icon">
                            {preGenPollingStatus === PREGENERATION_DOCUMENT_STATUS.UNAPPROVED_ERROR ? <ToastErrorIcon /> : 
                            preGenPollingStatus === PREGENERATION_DOCUMENT_STATUS.FAILED ? <ToastErrorIcon /> : 
                            preGenPollingStatus === PREGENERATION_DOCUMENT_STATUS.PARTIAL_SUCCESS ? <ToastErrorIcon /> : 
                            <SuccessIcon />}</span>
                        <div style={{ flexDirection: 'column'}}>                               
                            <div className={`custom-toast-title ${preGenPollingStatus}`}>
                                {MinutesTitle}
                            </div> 
                            <div className="custom-toast-message">{status_Message}</div>
                        </div>
                    </div>
                    <hr className="custom-toast-border" />
                    <div className="custom-toast-buttons">
                        <button className="dismiss" onClick={() => {
                            updatePregenerationAIMinutes(id, preGenPollingStatus, false)
                            removeToast(id)}}>{translate('TOAST_DISMISS_BUTTON')}</button>
                        <button className="action" onClick={() => {
                            updatePregenerationAIMinutes(id, preGenPollingStatus, false)
                            applicationRouter.navigateTo(`/taker/${id}`)} }>{translate('VIEW')}</button>
                    </div>
                </div>
              : null}
        </>
    );
};

export default StatusToast;
