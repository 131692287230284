import React from 'react'
import Tooltip, { TooltipProps } from '@material-ui/core/Tooltip'
import { Theme, createStyles } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { ColorBaseRichBlack, ColorBaseWhite } from 'assets/styles/variables'

const useStylesBootstrap = makeStyles((theme: Theme) =>
    createStyles({
        absolute: {
            position: 'absolute',
            bottom: theme.spacing(2),
            right: theme.spacing(3)
        },
        arrow: {
            color: ColorBaseRichBlack
        },
        tooltip: {
            backgroundColor: ColorBaseRichBlack,
            color: ColorBaseWhite,
            fontSize: '14px',
            fontWeight: 400,
            lineHeight: '20px',
            letterSpacing: '0.2px',
            textAlign: 'left',
            padding: 15,
            borderRadius: 10,
            position: 'relative'
        }
    })
)

export function AtlasTooltip(props: TooltipProps) {
    const classes = useStylesBootstrap()
    return <Tooltip arrow classes={classes} {...props} />
}
