import React, { Component } from 'react'
import { debounce } from 'rambdax'

//region Components Material

import styled from '@emotion/styled'
import { css, ClassNames } from '@emotion/react'

//endregion

//region Components

//endregion

//region Redux

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { actions as takerActions } from '../../reducers/minuteTakerReducer'

import takerSelectors from '../../selectors/minuteTakerSelectors'
import TextareaAutosize from 'react-autosize-textarea'
import LinearIndeterminate from 'components/shared/LinearProgress'

//endregion

//region Styles

//endregion

//region  Props

type Props = {
    actions: {
        syncSectionLocalDataChanges: (...args: any[]) => void
        saveSection: (...args: any[]) => void
        ensureSectionExpanded: (...args: any[]) => void
        setSelectedSection: (...args: any[]) => void
        updateSectionName: ({ id, minutesId, name }) => void
        updateSectionNamePending(): void
    }
    sectionId: string
    section: any
    isSelected: boolean
    readonly?: boolean
    currentMinuteItem: any
}

//endregion

//region Implementation

const StyledTextArea = styled(TextareaAutosize)`
    width: 100%;
    resize: none;
    background: transparent;
    outline: none;
    border: none;
    font-size: 18px;
    color: inherit;
    font-family: Source Sans Pro;
    font-weight: 600;
    margin: 9px 0;
    line-height: 24px;
    max-height: 60px;
`

class MinuteTitleArea extends Component<Props> {
    titleText: any = null
    componentDidMount() {
        const { section, currentMinuteItem } = this.props
        if (!section || section.id) {
            return
        }
        //When new section is created (id = null) we want to save it to server asap,
        //if we dont do it, we may face race condition while saving new section upon
        //first user updates.

        //The race condition is the following: user updates name and in second or so he updates section
        //Debouncer for name already waited for its time and send request to server.
        //BUT THEN server lags for a second or more, it gives time to second debouncer (for body updates lets say)
        //to trigger save again. As first save did not return yet, we have no new id assigned and save makes new copy
        //of the same section by sending second CREATE request. The only reliable way so far to prevent it is to
        //save section right away to have id generated asap.
        if (currentMinuteItem.attributes.pregeneratedMinutes === 'manual' || section.isManual) {
            this.props.actions.syncSectionLocalDataChanges(section)
            this.props.actions.saveSection(section)
        }
    }

    onTitleChange = (e) => {
        const { section } = this.props
        if (!section) {
            return
        }

        const name =
            e.target.value && e.target.value.length > 300
                ? e.target.value.substr(0, 300)
                : e.target.value

        section.name = name

        //There are several places generating debounced call to save.
        //While debounce is waiting we have to make sure we update underlying object
        //in the reducer so every new save initiator had very updated object to
        //apply his changes. If we dont do that, we can have a setuation when
        //a component tries to inject stale object into debounce process

        const { id, minutesId } = section

        this.props.actions.updateSectionNamePending()
        this.updateSectionName({ id, name, minutesId })
        this.forceUpdate()
    }

    updateSectionName = debounce(this.props.actions.updateSectionName, 2000)

    nativeTitleInputInvalid = () => {
        return !this.titleText || !this.titleText.input || !this.titleText.input.refs.input
    }
    setNativeTitleReadOnlyState = (isReadOnly) => {
        if (this.nativeTitleInputInvalid()) {
            return
        }

        this.titleText.input.refs.input.readOnly = isReadOnly
    }

    onItemActivate = () => {
        const { section } = this.props

        if (!section) {
            return
        }
        this.props.actions.ensureSectionExpanded(section.id)
        this.props.actions.setSelectedSection(section)

        this.setNativeTitleReadOnlyState(false)
    }

    isReadOnly = (titleText) => {
        //TP326754 R&D: Prevent Minute Taker freeze when selection across sections
        //Possible fix: We are trying to keep control readnoly all the time it is not in focus (see onBlur and rendering)
        if (this.nativeTitleInputInvalid()) {
            return true
        }

        return titleText.input.refs.input.readOnly
    }

    onBlur = () => {
        this.setNativeTitleReadOnlyState(true)
        //We call re-render so control would get back to readonly state (parent Material UI component)
        this.forceUpdate()
    }

    renderProgress = (): JSX.Element => {
        return <LinearIndeterminate loading={true} />
    }

    render() {
        const { section, isSelected, readonly } = this.props

        if (!section) {
            return null
        }

        const saveInfo = '' //process.env.NODE_ENV === 'development' ? <span style={getUpdatedStyle()}>{updatedLabel}</span> : null
        const name = section.name

        if (!section.id) {
            return this.renderProgress()
        }

        return (
            <ClassNames>
                {({ css }) => (
                    <div
                        className={css`
                            ${contentCls};
                        `}
                        data-test-selected={isSelected}>
                        {saveInfo}
                        <StyledTextArea
                            aria-labelledby={`select to edit ${name}`}
                            id="item_title"
                            aria-label={name}
                            className="FocusStyle"
                            ref={(c) => (this.titleText = c)}
                            onChange={this.onTitleChange}
                            onFocus={this.onItemActivate}
                            onBlur={this.onBlur}
                            value={name}
                            readOnly={readonly}
                        />
                    </div>
                )}
            </ClassNames>
        )
    }
}

//endregion

//region Export / Redux Bindings

const mapStateToProps = (state, props) => {
    return {
        section: takerSelectors.section(state.minuteTakerReducer, props.sectionId),
        currentMinuteItem: takerSelectors.currentMinuteItem(state.minuteTakerReducer)
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(takerActions, dispatch)
    }
}

const contentCls = css`
    height: 100%;
    display: flex;
    width: 100%;
    color: #1e1e1e;
    padding-right: 0px;
    min-height: 35px;
    align-items: center;
    justify-content: center;
`

export default connect(mapStateToProps, mapDispatchToProps)(MinuteTitleArea)

//endregion
