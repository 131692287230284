import { AI_CHAT_BOT_TYPE } from 'businesslayer/minutesSessionStore'

export const isChatBotEnable = (ai_chat_bot: AI_CHAT_BOT_TYPE, isAdmin: boolean) => {
    if (ai_chat_bot?.help_ai_for_bwa_enabled && isAdmin) {
        return true
    }
    if (ai_chat_bot?.help_ai_for_bwd_enabled && !isAdmin) {
        return true
    }
    return false
}
