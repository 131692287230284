import React, { useCallback, useRef } from 'react'
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    Paper,
    makeStyles,
    Tooltip
} from '@material-ui/core'
import { CustomSortIcon } from 'assets/icons/CustomSortIcon'

export interface DataRow {
    id: string
    name: string
    attended: string
    percentage: string
}

export interface Column {
    name: string
    selector: keyof DataRow
    sortable: boolean
    suffix?: string
}

interface ReactDataTableProps {
    columns: Column[]
    tableData: DataRow[]
    defaultSortKey?: keyof DataRow
}

const useStyles = makeStyles((theme) => ({
    tableContainer: {
        height: `calc(100vh - ${theme.spacing(40)}px)`,
        [theme.breakpoints.down('md')]: {
            height: `calc(100vh - ${theme.spacing(47)}px)`,
            maxHeight: '100%'
        }
    },
    table: {
        tableLayout: 'fixed',
        overflow: 'scroll',
        border: '1px solid #e6e6e6'
    },
    headRow: {
        backgroundColor: '#EBEEF2',
        height: '48px',
        borderBottom: '1px solid #e6e6e6',
        fontWeight: 600,
        color: '#1E1E1E',
        fontFamily: 'Source Sans Pro',
        fontSize: '16px',
        lineHeight: '20px',
        letterSpacing: '0px',
        textAlign: 'left',
        '&>span:focus': {
            outline: '1px solid #6790cc'
        }
    },
    rows: {
        height: '48px'
    },
    fixedHeader: {
        position: 'sticky',
        top: -0.5,
        zIndex: 1,
        backgroundColor: theme.palette.background.paper
    },
    cell: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        fontFamily: 'Source Sans Pro',
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '20px',
        letterSpacing: '0px',
        textAlign: 'left'
    },
    customSortIcon: {
        right: theme.spacing(1), // Using theme spacing
        transition: 'transform 0.2s ease-in-out',
        display: 'grid',
        paddingLeft: '15px'
    },
    overflowContent: {
        maxWidth: '100%', // Adjust this value as needed
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap'
    }
}))

const sortData = (
    data: DataRow[],
    sortKey: keyof DataRow | null,
    isAscending: boolean
): DataRow[] => {
    const sorted = [...data]
    if (sortKey !== null) {
        sorted.sort((a, b) => {
            const aValue = a[sortKey]
            const bValue = b[sortKey]

            if (typeof aValue === 'string' && typeof bValue === 'string') {
                return aValue.localeCompare(bValue) * (isAscending ? 1 : -1)
            } else if (typeof aValue === 'number' && typeof bValue === 'number') {
                return (aValue - bValue) * (isAscending ? 1 : -1)
            } else {
                return String(aValue).localeCompare(String(bValue)) * (isAscending ? 1 : -1)
            }
        })
    }
    return sorted
}
const ReactDataTable: React.FC<ReactDataTableProps> = React.memo(
    ({ columns, tableData, defaultSortKey = null }) => {
        const [sortKey, setSortKey] = React.useState<keyof DataRow | null>(defaultSortKey)
        const [isAscending, setIsAscending] = React.useState(false)
        const classes = useStyles()

        const handleSort = useCallback(
            (newSortKey: keyof DataRow) => {
                if (newSortKey === sortKey) {
                    setIsAscending(!isAscending)
                } else {
                    setSortKey(newSortKey)
                    setIsAscending(true)
                }
            },
            [sortKey, setIsAscending, setSortKey, isAscending]
        )

        const sortedData = React.useMemo(() => sortData(tableData, sortKey, isAscending), [
            tableData,
            sortKey,
            isAscending
        ])

        return (
            <TableContainer component={Paper} className={classes.tableContainer}>
                <Table className={classes.table}>
                    <TableHead>
                        <TableRow className={classes.fixedHeader}>
                            {columns.map((column) => (
                                <SortableTableHeader
                                    key={column.name}
                                    column={column}
                                    sortKey={sortKey}
                                    isAscending={isAscending}
                                    onClick={() => handleSort(column.selector)}
                                />
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sortedData.map((row) => (
                            <DataTableRow
                                key={row.name}
                                row={row}
                                classes={classes}
                                columns={columns}
                            />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        )
    }
)

export default ReactDataTable
interface DataTableRowProps {
    row: DataRow
    classes: Record<string, string>
    columns: Column[]
}

const DataTableRow: React.FC<DataTableRowProps> = React.memo(({ row, classes, columns }) => {
    const cellRefs = useRef<Array<HTMLDivElement | null>>([]) // Ref to store refs of the cell elements
    const handleOverflow = useCallback(
        (index: number): boolean => {
            const cell = cellRefs.current[index]
            if (cell) {
                return cell.scrollWidth > cell.clientWidth
            }
            return false
        },
        [cellRefs]
    )
    // working on it for the tooltip
    return (
        <TableRow key={row.name} className={classes.rows}>
            {columns.map((col, index) => (
                <TableCell
                    key={row[col.selector]}
                    className={classes.cell}
                    ref={(ref) => (cellRefs.current[index] = ref as HTMLDivElement | null)} // Store cell refs
                >
                    {handleOverflow(index) || row[col.selector].length > 50 ? (
                        <Tooltip
                            arrow
                            title={row[col.selector]}
                            enterDelay={500}
                            placement="top-start">
                            <div className={classes.overflowContent}>{row[col.selector]}</div>
                        </Tooltip>
                    ) : (
                        <div className={classes.overflowContent}>
                            {row[col.selector]}
                            {col.suffix}
                        </div>
                    )}
                </TableCell>
            ))}
        </TableRow>
    )
})

interface SortableTableHeaderProps {
    column: Column
    sortKey: keyof DataRow | null
    isAscending: boolean
    onClick: () => void
}

const formatLabelForAnalytics = (label) => {
    return label
        .split(' ')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join('')
}

const SortableTableHeader: React.FC<SortableTableHeaderProps> = React.memo(
    ({ column, sortKey, isAscending, onClick }) => {
        const classes = useStyles()
        return (
            <TableCell
                data-analytics={`MM-Sort-${formatLabelForAnalytics(column.name)}`}
                key={column.name}
                className={classes.headRow}>
                {column.sortable ? (
                    <TableSortLabel
                        active={sortKey === column.selector}
                        direction={isAscending ? 'asc' : 'desc'}
                        onClick={onClick}
                        IconComponent={() => (
                            <CustomSortIcon
                                isAscending={isAscending}
                                selected={sortKey === column.selector}
                            />
                        )}>
                        {column.name}
                    </TableSortLabel>
                ) : (
                    column.name
                )}
            </TableCell>
        )
    }
)
