import React, { useCallback, useMemo } from 'react'
import { Box, Checkbox, makeStyles, Typography } from '@material-ui/core'
import i18n from 'common/util/internationalization'
import { InputLabelStyled } from 'components/minutetaker/components/form/AssigneeChipInputField'
import { NewDialogTextField } from 'components/shared/StyledComponents'
import TextLengthCounter from 'components/shared/TextLengthCounter'
import { MAX_MESSAGE, MAX_SUBJECT } from '../../validation'
import { getReviewNames } from '../../util'
import { EmailNotificationProps, INVITEE_CONSTANTS } from '../../types'
import RestrictedWarningIcon from 'assets/icons/RestrictedWarningIcon'
import { ColorBaseWhite } from 'assets/styles/variables'

const useStyles = makeStyles(() => ({
    messageInputStyle: {
        marginTop: '8px',
        marginBottom: '0.5rem',
        '& .MuiOutlinedInput-inputMultiline': {
            minHeight: '70px',
            resize: 'vertical'
        },
        '& > p': {
            margin: '0px'
        }
    },
    textEditStyle: {
        paddingTop: '8px',
        '& > p': {
            margin: '0px'
        }
    },
    emailNotification: {
        color: 'var(--semantic-color-action-active, #252C44)',
        fontFamily: 'Source Sans Pro',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: '20px',
        marginBottom: '24px',
        borderTop: '1px solid #E6E6E6',
        paddingTop: '24px'
    },
    switchLabelStyle: {
        color: '#000',
        fontFamily: 'Source Sans Pro',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '20px',
        marginTop: '12px'
    },
    notificationHeader: {
        color: '#000',
        fontFamily: 'Source Sans Pro',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: '20px'
    },
    notificationMessage: {
        display: 'flex',
        marginTop: '4px',
        color: '#000',
        fontFamily: 'Source Sans Pro',
        fontSize: '13px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '16px'
    },
    notificationTitle: {
        fontFamily: 'Source Sans Pro',
        fontSize: '13px',
        fontWeight: 400,
        lineHeight: '16px',
        letterSpacing: '0px',
        textAlign: 'left',
        paddingBottom: '8px',
        placeItems: 'center',
        display: 'flex'
    },
    checkbox: {
        padding: '0px',
        '&.Mui-checked': {
            color: '#2F3B4D'
        },
        '&.Mui-disabled': {
            color: 'rgba(0, 0, 0, 0.26)'
        },
        marginRight: '8px',
        width: '24px',
        height: '24px',
        '&.Mui-focusVisible, &:active': {
            boxShadow: `0px 0px 0px 3px #0774EE, 0px 0px 0px 1px ${ColorBaseWhite}`,
            borderRadius: '4px'
        }
    },
    checkboxTitle: {
        fontFamily: 'Source Sans Pro',
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '20px',
        letterSpacing: '0px',
        textAlign: 'left',
        padding: '0px',
        color: '#000000'
    },
    notificationContainer: {
        gap: '12px',
        margin: '12px 0'
    },
    warningBox: {
        height: '50px',
        padding: '4px 8px',
        borderRadius: '4px',
        border: '1px solid #EAA14B',
        gap: '8px',
        display: 'flex',
        margin: '10px 0'
    },
    warningIcon: {
        placeSelf: 'center',
        padding: '10px'
    },
    warningText: {
        color: '#000',
        fontFamily: 'Source Sans Pro',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: '20px',
        textAlign: 'left',
        textOverflow: 'ellipsis',
        textWrap: 'nowrap',
        overflow: 'hidden',
        marginTop: 'auto',
        marginBottom: 'auto'
    },
    warningTextContainer: {
        gap: '4px',
        display: 'grid'
    },
    emailNotificationContainer: {
        marginLeft: '2rem',
        marginBottom: '24px',
        color: '#000',
        fontFamily: 'Source Sans Pro',
        fontSize: '13px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '16px'
    },
    label: {
        fontFamily: 'Source Sans Pro',
        fontSize: '13px',
        fontWeight: 400,
        lineHeight: '16px',
        letterSpacing: '0px',
        textAlign: 'left'
    }
}))

const EmailNotification = ({
    updateReviewers,
    minuteTakerState,
    chipOptions,
    dataState,
    errorObject,
    setErrorObject,
    showHasHtmlMessage,
    setShowHasHtmlMessage
}: EmailNotificationProps) => {
    const classes = useStyles()
    const {
        cancelled_reviews,
        notification_send,
        edited_reviews,
        subject,
        body,
        new_invitees
    } = minuteTakerState.reviewers

    const {
        cancelledReviewsName,
        editedReviewsName,
        showEditedReview,
        showCancelledReview,
        showNewInvitee,
        newReviewsName
    } = useMemo(() => {
        const cancelledReviewsName = getReviewNames(chipOptions, cancelled_reviews, 'review_id')
        const editedReviewsName = getReviewNames(chipOptions, edited_reviews, 'review_id')
        const newReviewsName = getReviewNames(chipOptions, new_invitees, 'id')
        return {
            cancelledReviewsName,
            editedReviewsName,
            newReviewsName,
            showCancelledReview: Boolean(cancelled_reviews.length),
            showEditedReview: Boolean(edited_reviews.length),
            showNewInvitee: Boolean(new_invitees.length)
        }
    }, [edited_reviews, cancelled_reviews, chipOptions, new_invitees])

    const showNoReviewMessage = !showEditedReview && !showCancelledReview && !showNewInvitee

    const handleChange = useCallback(
        (e) => {
            const updatedValue = e.target.type === 'checkbox' ? e.target.checked : e.target.value
            const data = { ...minuteTakerState.reviewers }
            if (e.target.type === 'checkbox') {
                data.subject = i18n.t('SEND_INVITATION_DEFAULT_SUBJECT')
                data.body = i18n.t('SEND_INVITATION_DEFAULT_MESSAGE', {
                    workroomName: dataState.currentMinuteItem.attributes.committeeName,
                    minutesName: dataState.currentMinuteItem.attributes.title
                })
            }
            setErrorObject(false)
            if (setShowHasHtmlMessage) {
                setShowHasHtmlMessage({ body: false, subject: false })
            }

            updateReviewers({
                ...data,
                [e.target.name]: updatedValue
            })
        },
        [minuteTakerState, updateReviewers, dataState, setErrorObject, setShowHasHtmlMessage]
    )
    const handleCheckboxKeyDown = useCallback(
        (event, checkboxName) => {
            if (event.key === 'Enter') {
                event.preventDefault()
                handleChange({
                    target: {
                        name: checkboxName,
                        checked: !notification_send,
                        type: 'checkbox'
                    }
                })
            }
        },
        [handleChange, notification_send]
    )
    const renderTitle = useCallback(
        (title, checkboxName) => (
            <Box className={classes.notificationTitle}>
                <Checkbox
                    disableRipple
                    name={checkboxName}
                    checked={notification_send}
                    data-analytics="MM-CustomInvitationEmail"
                    onChange={handleChange}
                    onKeyDown={(event) => handleCheckboxKeyDown(event, checkboxName)}
                    className={classes.checkbox}
                />
                <Typography className={classes.checkboxTitle}>{title}</Typography>
            </Box>
        ),
        [classes, handleChange, notification_send, handleCheckboxKeyDown]
    )

    const renderMessage = useCallback(
        (title, message, names) => (
            <Box className={classes.notificationContainer}>
                <Typography variant="h3" className={classes.notificationHeader}>
                    {title}
                </Typography>
                <Typography className={classes.notificationMessage}>{message}</Typography>
                <Typography className={classes.switchLabelStyle}>{names}</Typography>
            </Box>
        ),
        [classes]
    )
    const WarningElement = useMemo(
        () => (
            <Box className={classes.warningBox}>
                <Box className={classes.warningIcon}>
                    <RestrictedWarningIcon />
                </Box>
                <Box className={classes.warningTextContainer}>
                    <Typography className={classes.warningText}>
                        {i18n.t('NO_CHANGE_MESSAGE')}
                    </Typography>
                </Box>
            </Box>
        ),
        [classes]
    )

    return (
        <>
            <Typography className={classes.emailNotification}>
                {i18n.t('REVIEWER_EMAIL_NOTIFICATION')}
            </Typography>
            <Box>
                {showNewInvitee &&
                    renderMessage(
                        i18n.t('REVIEWER_NEW_NOTIFICATION'),
                        i18n.t('REVIEWER_NEW_NOTIFICATION_MESSAGE'),
                        newReviewsName
                    )}
                {showNewInvitee &&
                    renderTitle(
                        i18n.t('EMAIL_NOTIFICATION_TO_REVIEWERS_MESSAGE'),
                        'notification_send'
                    )}
                {showNewInvitee && i18n.t('EMAIL_NOTIFICATION_TO_REVIEWERS_MESSAGE') && (
                    <Typography className={classes.emailNotificationContainer}>
                        {i18n.t('EMAIL_NOTIFICATION_TO_REVIEWERS')}
                    </Typography>
                )}

                {showNewInvitee && notification_send && (
                    <Box>
                        <Box>
                            <InputLabelStyled>{i18n.t('NOTIFICATION_SUBJECT')}*</InputLabelStyled>
                            <NewDialogTextField
                                required
                                className={classes.textEditStyle}
                                variant="outlined"
                                data-testid="ReviewInvitationModal_Subject"
                                value={subject}
                                name={INVITEE_CONSTANTS.SUBJECT}
                                onChange={handleChange}
                                multiline
                                maxLength={MAX_SUBJECT}
                                error={
                                    errorObject &&
                                    (subject.length === 0 || showHasHtmlMessage?.subject)
                                }
                                helperText={
                                    <TextLengthCounter
                                        maxValue={MAX_MESSAGE}
                                        value={subject}
                                        errorText={`${
                                            errorObject && subject.length === 0
                                                ? i18n.t('INVALID_EMAIL_SUBJECT')
                                                : showHasHtmlMessage?.subject
                                                ? i18n.t('NO_HTML_MESSAGE')
                                                : INVITEE_CONSTANTS.EMPTY
                                        }`}
                                    />
                                }
                            />
                        </Box>
                        <Box>
                            <InputLabelStyled className={classes.label}>
                                {i18n.t('NOTIFICATION_BODY')}
                            </InputLabelStyled>
                            <NewDialogTextField
                                className={classes.messageInputStyle}
                                variant="outlined"
                                required
                                value={body}
                                name={INVITEE_CONSTANTS.BODY}
                                onChange={handleChange}
                                multiline
                                maxLength={MAX_MESSAGE}
                                error={
                                    errorObject && (body.length === 0 || showHasHtmlMessage?.body)
                                }
                                helperText={
                                    <TextLengthCounter
                                        maxValue={MAX_MESSAGE}
                                        value={body}
                                        errorText={`${
                                            errorObject && body.length === 0
                                                ? i18n.t('INVALID_EMAIL_BODY')
                                                : showHasHtmlMessage?.body
                                                ? i18n.t('NO_HTML_MESSAGE')
                                                : INVITEE_CONSTANTS.EMPTY
                                        }`}
                                    />
                                }
                            />
                        </Box>
                    </Box>
                )}
                {showCancelledReview &&
                    renderMessage(
                        i18n.t('CANCELLATION_NOTIFICATION'),
                        i18n.t('CANCELLATION_NOTIFICATION_MESSAGE'),
                        cancelledReviewsName
                    )}
                {showEditedReview &&
                    renderMessage(
                        i18n.t('CHANGE_NOTIFICATION'),
                        i18n.t('CHANGE_NOTIFICATION_MESSAGE'),
                        editedReviewsName
                    )}
                {showNoReviewMessage && WarningElement}
            </Box>
        </>
    )
}

export default EmailNotification
