import moment from 'moment'

type TemplateObject = {
    data: {
        type: string
        attributes: {
            title: string | null
            include_book_structure: boolean
            include_sub_tabs: boolean
            include_logo: boolean
            logo: any
            logo_filename: any
            meeting_dates: MeetingDate[] | null
            attendees: Attendee[] | null
            location: string | null
            pregenerated_minutes: boolean
        }
        relationships: {
            committee: {
                data: {
                    id: string | number | null
                    type: 'committees'
                }
            }
            book: {
                data: {
                    id: string | number
                    type: 'books'
                }
            } | null
            event: any
        }
    }
}

const templateObject = (): TemplateObject => {
    return {
        data: {
            type: 'minutes_documents',
            attributes: {
                title: null,
                include_book_structure: false,
                include_sub_tabs: false,
                include_logo: true,
                logo: null,
                logo_filename: null,
                meeting_dates: null,
                attendees: null,
                location: null,
                pregenerated_minutes: false
            },
            relationships: {
                committee: {
                    data: {
                        id: null,
                        type: 'committees'
                    }
                },
                book: null,
                event: null
            }
        }
    }
}

const getDateTimes = (item) => {
    if (!item.meetingDates || !item.meetingDates.length) {
        return null
    }

    if (item.meetingDates.length === 1 && !item.meetingDates[0].startTime) {
        //This is placeholder case
        return null
    }

    const formatted = item.meetingDates
        .filter((item) => (item.startTime ? true : false))
        .map((date) => {
            let startDateTime = moment(date.date)
            startDateTime.set({
                hour: 0,
                minute: 0,
                second: 0
            })

            let starTime = moment(date.startTime)
            let endTime = !!date.endTime ? moment(date.endTime) : null

            return {
                start_date: startDateTime.toDate().toISOString(),
                start_hour: starTime.get('hour'),
                start_minute: starTime.get('minute'),
                end_date: !!endTime ? startDateTime.toDate().toISOString() : null,
                end_hour: date.end_hour,
                end_minute: date.end_minute,
                time_zone_code: date.timeZoneCode
            }
        })
    return formatted
}

const getAttendees = (item) => {
    if (!item.attendees || !item.attendees.length) {
        return null
    }
    const formatted = item.attendees.map((attendee) => {
        let id = isNaN(attendee.value) ? null : parseInt(attendee.value, 10)
        if (attendee.id) {
            id = attendee.id
        }
        return {
            email: attendee.email || null,
            display_name: attendee.text,
            external_id: id
        }
    })
    return formatted
}

const getBook = (item): any => {
    if (!item.bookId) {
        return null
    }

    return {
        data: {
            id: item.bookId,
            type: 'books'
        }
    }
}

export const serialize = ({ item, committeeId }) => {
    const dateTimes = getDateTimes(item)
    const attendees = getAttendees(item)
    const book = getBook(item)

    const requestData = templateObject()
    requestData.data.attributes.title = item.title
    requestData.data.attributes.meeting_dates = dateTimes
    requestData.data.attributes.attendees = attendees
    requestData.data.attributes.location = item.location
    requestData.data.attributes.logo = item.logo
    requestData.data.attributes.logo_filename = item.logoFilename
    requestData.data.attributes.include_logo = item.includeLogo
    requestData.data.attributes.include_book_structure = item.includeBookStructure
    requestData.data.attributes.include_sub_tabs = item.includeSubTabs
    requestData.data.relationships.committee.data.id = committeeId
    requestData.data.relationships.book = item.duplicateOption ? null : book
    requestData.data.attributes.pregenerated_minutes = item.pregenerated_minutes
    return JSON.stringify(requestData)
}

export const serializeUnnormalized = (item: MinutesUnnormalized) => {
    const book = getBook(item)

    const requestData = templateObject()
    requestData.data.attributes.title = item.title
    requestData.data.attributes.meeting_dates = item.meeting_dates
    requestData.data.attributes.attendees = item.attendees
    requestData.data.attributes.location = item.location
    requestData.data.attributes.logo = item.logo
    requestData.data.attributes.logo_filename = item.logo_filename
    requestData.data.attributes.include_logo = item.include_logo
    requestData.data.attributes.include_book_structure = item.include_book_structure
    requestData.data.attributes.include_sub_tabs = item.include_sub_tabs
    requestData.data.relationships.committee.data.id = item.committee.id
    requestData.data.relationships.book = item.duplicateOption ? null : book
    requestData.data.attributes.pregenerated_minutes=item.pregenerated_minutes
    
    return JSON.stringify(requestData)
}

const createReportTemplate = (
    meetingDetails: CreateReportPayload
): {
    id: string | number
    name: string
    start_date: string
    end_date: string
    meetings: Array<{
        document_id: string | number
        attendees: Array<{ id: string | number; minutes_contact_id: any; name: string }>
    }>
} => {
    const arr = meetingDetails.selectedCommittee.map((data) => {
        return {
            document_id: data.id,
            attendees: data.attendees.map((attendee) => {
                const id: string | number =
                    typeof attendee.id === 'number' ? attendee.id : String(attendee.id)
                return {
                    id,
                    minutes_contact_id: attendee.minutes_contact_id,
                    name: attendee.display_name
                }
            })
        }
    })

    return {
        id: meetingDetails.committeeId,
        name: meetingDetails.committeeName,
        start_date: meetingDetails.startDate.toDate().toISOString(),
        end_date: meetingDetails.endDate.toDate().toISOString(),
        meetings: arr
    }
}

export const createReportPayload = (newReportData: CreateReportPayload): string => {
    const committees: Array<Object> = []
    const requestData = createReportTemplate(newReportData)
    committees.push(requestData)
    const apiBody = JSON.stringify({ committees })

    return apiBody
}
