import React, { FC, useMemo, useEffect, useState } from 'react'

import i18n from 'i18next'
import { useCommitteesState } from 'components/contexts/committee-context'
import {
    MAX_LOCATION_LENGTH,
    MAX_TITLE_LENGTH,
    validateMinutesTitleField,
    validateMinutesLocationField,
    validateMinutesCommitteeField,
    truncateString,
    validateAttendees,
    MAX_ATTENDEE_COUNT
} from 'common/util/validators/minutes-properties/minutes-properties-validators'
import { AtlasTextFieldAdapter } from 'components/text-field/text-field'
import { CommitteeSelect } from 'components/popovers/committee-select/committee-select'

import { EditMode } from './use-minutes-properties-modal'

import { Form, Field, FormSpy } from 'react-final-form'
import arrayMutators from 'final-form-arrays'
import { FormRow, FormBody } from 'components/layout/modal-form-layout'
import {
    MetaDataList,
    MetaDataColumnList
} from 'components/meta-data-column-list/meta-data-column-list'
import { RadioGroup, FormControlLabel, Radio, Typography } from '@material-ui/core'
import { MeetingDatesFieldControl } from 'components/meeting-date-time-control/meeting-dates-field-control'
import { Tools, MutableState } from 'final-form'
import { EMPTY_MEETING_DATE } from './minutes-properties-form-functions'
import { AttendeesChipInputAdapter } from 'components/chip-input/minutes-manager-attendees/attendees-chip-input'
import { pathOr, path, keys } from 'rambdax'
import { BookStructureField } from './book-structure-field'
import { LogoField } from './logo-field'
import { ClassNames } from '@emotion/react'
import InfoIcon from 'assets/icons/InfoIcon'

const DEFAULT_DUPLICATE_OPTION = 'sections_only' as DuplicateMinutesOption
export const MINUTES_PROPERTIES_FORM_NAME = 'minutesPropertiesForm'

type Props = {
    initialFormValues: MinutesPropertiesFormValues
    minutesMetaData: MetaDataList
    onValidationChange: (isFormValid) => void
    onSave: (values: any) => void
    editMode: EditMode
    showSummaryStatus: { status: string }
}

export const MinutesPropertiesForm: FC<Props> = ({
    minutesMetaData,
    editMode,
    onSave,
    initialFormValues,
    onValidationChange,
    showSummaryStatus
}) => {
    const { adminCommittees } = useCommitteesState()
    const memoInitialValues = useMemo(() => initialFormValues, [initialFormValues])
    const onValidationChangeHandler = (value) => {
        setTimeout(function () {
            onValidationChange(value)
        }, 0)
    }
    const STATUS_READY = 'ready'
    const [isProcessingNotificationVisible, setIsProcessingNotificationVisible] = useState(
        showSummaryStatus?.status?.toLowerCase() !== STATUS_READY
    )

    useEffect(() => {
        setIsProcessingNotificationVisible(
            showSummaryStatus?.status?.toLowerCase() !== STATUS_READY
        )
    }, [showSummaryStatus])

    return (
        <Form
            initialValues={memoInitialValues}
            onSubmit={onSave}
            subscription={{ submitting: true, pristine: true }}
            mutators={{
                ...arrayMutators,
                resetDateFields,
                resetAttendeesField,
                setLogoFields
            }}
            keepDirtyOnReinitialize={true}
            render={({ handleSubmit, form: { mutators, getState, getFieldState, change } }) => {
                // Don't let the user enter attendees if they haven't chosen a committee
                const formState = getState()
                const isAttendeesDisabled = !path<string>('values.committee.name', formState)
                const bookId = path<string>('values.bookId', formState)
                const bookTitle = path<string>('values.bookTitle', formState)
                const isCreateLinkedBook = !!bookId && editMode === 'CREATE'
                return (
                    <ClassNames>
                        {({ css }) => (
                            <FormBody id={MINUTES_PROPERTIES_FORM_NAME} onSubmit={handleSubmit}>
                                <FormSpy
                                    // Callback to enable/disable save button based on form validation
                                    onChange={({ valid }) => onValidationChangeHandler(valid)}
                                />
                                <FormRow visibleWhen={editMode === 'EDIT'}>
                                    <MetaDataColumnList
                                        metaData={minutesMetaData}
                                        columns={3}
                                        maxWidth={890}
                                        labelClass="text-medium-grey"
                                        valueClass="text-medium-grey"
                                    />
                                </FormRow>
                                <FormRow visibleWhen={editMode === 'DUPLICATE'}>
                                    <Field
                                        name="duplicateOption"
                                        type="radio"
                                        render={({ input }) => (
                                            <RadioGroup
                                                row
                                                defaultValue={DEFAULT_DUPLICATE_OPTION}
                                                {...input}>
                                                <FormControlLabel
                                                    value="sections_only"
                                                    label={i18n.t('DUPLICATE_SECTIONS_ONLY')}
                                                    data-testid="DuplicateMinutes_SectionsOnly"
                                                    control={<Radio />}
                                                />
                                                <FormControlLabel
                                                    value="minutes_and_sections"
                                                    label={i18n.t('DUPLICATE_MINUTES_SECTIONS')}
                                                    data-testid="DuplicateMinutes_MinutesAndSections"
                                                    control={<Radio />}
                                                />
                                            </RadioGroup>
                                        )}
                                    />
                                </FormRow>
                                <FormRow visibleWhen={isCreateLinkedBook} verticalSpacing="small">
                                    <BookStructureField bookTitle={bookTitle} />
                                </FormRow>
                                {showSummaryStatus?.status !== '' &&
                                    isProcessingNotificationVisible && (
                                        <FormRow
                                            visibleWhen={
                                                formState.values.bookStructure === 'AI_ASSIST'
                                            }
                                            verticalSpacing="small">
                                            <div
                                                style={{
                                                    border: '1px solid #385F99',
                                                    borderRadius: '4px',
                                                    height: '48px',
                                                    padding: '4px 8px',
                                                    gap: '8px',
                                                    placeContent: 'baseline',
                                                    width: '100%',
                                                    placeItems: 'center',
                                                    display: 'flex'
                                                }}>
                                                <InfoIcon />
                                                <Typography>
                                                    {i18n.t('PROCESSING_NOTIFICATION')}
                                                </Typography>
                                            </div>
                                        </FormRow>
                                    )}

                                <FormRow verticalSpacing="small">
                                    <Field
                                        name="committee"
                                        validate={validateMinutesCommitteeField}>
                                        {({ input: { onChange, ...inputProps } }) => {
                                            return (
                                                <div style={{ width: '100%' }}>
                                                    <CommitteeSelect
                                                        onChange={(value) => {
                                                            mutators.resetAttendeesField()
                                                            onChange(value)
                                                        }}
                                                        isReadonly={
                                                            editMode === 'EDIT' ||
                                                            isCreateLinkedBook
                                                        }
                                                        committees={adminCommittees}
                                                        {...inputProps}
                                                    />
                                                </div>
                                            )
                                        }}
                                    </Field>
                                </FormRow>
                                <FormRow verticalSpacing="small">
                                    <Field
                                        name="title"
                                        data-testid="MinutesProperties_Title"
                                        validate={validateMinutesTitleField}
                                        formatOnBlur
                                        format={(value) => truncateString(value, MAX_TITLE_LENGTH)}
                                        className={css`
                                            p {
                                                margin-left: 0px;
                                                margin-right: 7px;
                                            }
                                        `}
                                        label={i18n.t('MINUTES_DOCUMENT_TITLE.default')}
                                        component={AtlasTextFieldAdapter}
                                        required
                                        placeholder={i18n.t('MEETING_MINUTES_NAME_HINT')}
                                        maxLength={MAX_TITLE_LENGTH}
                                    />
                                </FormRow>
                                <FormRow verticalSpacing="small">
                                    <Field
                                        name="meeting_dates"
                                        component={MeetingDatesFieldControl}
                                        formMutators={mutators}
                                        getFieldState={getFieldState}
                                        change={change}
                                    />
                                </FormRow>
                                <FormRow verticalSpacing="small">
                                    <Field
                                        name="attendees"
                                        data-testid="MinutesProperties_Attendees"
                                        // This will be set to true until the user chooses a committee
                                        // in the Create Document modal
                                        isDisabled={isAttendeesDisabled}
                                        validate={validateAttendees}
                                        component={AttendeesChipInputAdapter}
                                        maxAttendeesCount={MAX_ATTENDEE_COUNT}
                                    />
                                </FormRow>
                                <FormRow verticalSpacing="small">
                                    <Field
                                        name="location"
                                        data-testid="MinutesProperties_Location"
                                        validate={validateMinutesLocationField}
                                        component={AtlasTextFieldAdapter}
                                        className={css`
                                            p {
                                                margin-right: 7px;
                                            }
                                        `}
                                        label={i18n.t('LOCATION')}
                                        formatOnBlur
                                        format={(value) =>
                                            truncateString(value, MAX_LOCATION_LENGTH)
                                        }
                                        placeholder={i18n.t('LOCATION_HINT')}
                                        maxLength={MAX_LOCATION_LENGTH}
                                    />
                                </FormRow>
                                <FormRow verticalSpacing="small">
                                    <LogoField
                                        setLogoFields={mutators.setLogoFields}
                                        editMode={editMode}
                                    />
                                </FormRow>
                            </FormBody>
                        )}
                    </ClassNames>
                )
            }}
        />
    )
}
// Final-Form Custom Mutator
// Called when user clicks "Clear" or
// after adding a new date and clicking the plus sign
const resetDateFields = (
    _,
    state: MutableState<any, Partial<any>>,
    tools: Tools<MinutesPropertiesFormValues, MinutesPropertiesFormValues>
) => {
    // Rest values and field state of date fields
    keys(EMPTY_MEETING_DATE).forEach((fieldName) => {
        tools.changeValue(state as any, fieldName, () => EMPTY_MEETING_DATE[fieldName])
        tools.resetFieldState(fieldName)
    })
}

/**
 * Final-Form custom mutator
 * If, while creating a new document, the user changes the
 * selected committee then reset the attendees list
 * @param _
 * @param state
 * @param tools
 */
const resetAttendeesField = (
    _,
    state: MutableState<any, Partial<any>>,
    tools: Tools<MinutesPropertiesFormValues, MinutesPropertiesFormValues>
) => {
    const defaultAttendeeChips = pathOr([], 'formState.initialValues.attendees', state)
    tools.changeValue(state as any, 'attendees', () => defaultAttendeeChips)
}

/**
 * Final-Form custom mutator
 * Used to set derived log details on logo upload
 * @param _
 * @param state
 * @param tools
 */
const setLogoFields = (
    args: Array<{
        logo_filename: string
        logo: string
        include_logo: boolean
        logoFilename: string
    }>,
    state: MutableState<any, Partial<any>>,
    tools: Tools<MinutesPropertiesFormValues, MinutesPropertiesFormValues>
) => {
    const logoFieldValues = args[0]
    const { logo_filename, logo, include_logo, logoFilename } = logoFieldValues

    tools.changeValue(state as any, 'logo_filename', () => logo_filename)
    tools.changeValue(state as any, 'logoFilename', () => logoFilename)
    tools.changeValue(state as any, 'logo', () => logo)
    tools.changeValue(state as any, 'include_logo', () => include_logo)
}

/**
 * Function to submit this form externally
 * It emits the 'submit' event on the <form> and
 * in turn executes the handleSubmit function in final-form
 */
export const submitMinutesPropertiesForm = () => {
    const form = document.getElementById(MINUTES_PROPERTIES_FORM_NAME)
    // final-form is listening for this event...
    if (form) form.dispatchEvent(new Event('submit', { bubbles: true, cancelable: true }))
}
